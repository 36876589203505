body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.white-space-pre {
  white-space: pre-wrap;
}

// global style for missing snippet data
.missing-data {
  font-style: italic;
}

.under-construction {
  color: $govuk-secondary-text-colour;
}

@media (min-width: 1020px) {
  .scrollbar-fix {
    padding-left: calc(100vw - 100%); //scroll bar jump
  }
}

.anonymous-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.side-panel {
  padding-top: 10px;
  border-top: 10px solid $govuk-border-colour;
}

.related-items {
  li {
    margin-bottom: 20px;
  }

  a.left-border {
    border-left: 2px solid $govuk-border-colour;
    padding-left: 7px;
  }

  i {
    padding-right: 5px;
  }
}

.inline-button {
  margin-top: 30px;
  margin-bottom: 20px;

  > button {
    min-width: 80px;
  }

  > * {
    vertical-align: middle;
    display: inline-block;
    margin: 0 10px 0 0;
  }
}

@keyframes highlight {
  from {
    opacity: 0.0;
  }

  to {
    opacity: 1.0;
  }
}
