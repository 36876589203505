// variable overrides
$font-family: "Roboto", sans-serif;
$govuk-font-family: $font-family;
$govuk-font-family-tabular: $font-family;
$govuk-font-family-print: $font-family;

@import "~govuk-frontend/govuk/helpers/colour";
$govuk-focus-colour: govuk-colour("dark-grey");
$govuk-focus-light: govuk-colour("light-grey");

$govuk-focus-outline: 5px;

$govuk-link-colour: govuk-colour("dark-grey");
$govuk-link-visited-colour: govuk-colour("dark-grey");

$header-height: 80px;
$header-height-mobile: 70px;
