@import 'govuk-variables';


// import library
@import '~govuk-frontend/govuk/all';

// remove the box shadow focus style globally
// TODO: revisit this to see if we can incorporate gracefully
:focus {
  box-shadow: none !important;
}

.govuk-back-link {
  padding: 7px 7px 7px 18px;

  &:before {
    left: 4px;
  }
}

// ensure that button test is always white in all states
.govuk-button {
  color: white !important;
}

// Basic
.govuk-border, .theme-border, .theme-border-secondary {
  border-color: $govuk-border-colour
}

// Link
.theme-link {
  color: #000;
}

.caption {
  color: govuk-colour("dark-grey");
  p {
    margin: 2px;
  }
}
.govuk-button, .govuk-heading-l { letter-spacing: 0.01em; }

.govuk-heading-m, .govuk-heading-s { letter-spacing: 0.005em; }

// apply gov uk style to all links even if not marked with .govuk-link
a {
  @include govuk-link-common;
  @include govuk-link-style-default;

  //Don't include the print link expansion as we have our own .print-expand class for this
  // @include govuk-link-print-friendly;
}

// link styling is screen only
@media screen {

  a {
    padding-top:2px;
    padding-bottom:2px;
  }

  .govuk-breadcrumbs__link,
  .govuk-footer__link {
    padding: 5px;
  }

  .govuk-accordion__section-button,
  .govuk-accordion__open-all {
    padding: 5px !important;
  }

  // TODO: use mixin for padding
  a:not(.govuk-button),
  .govuk-breadcrumbs__link,
  .govuk-footer__link,
  .govuk-back-link,
  .govuk-accordion__section-button,
  .govuk-accordion__open-all {
    &:focus {
      box-shadow: 0 $govuk-focus-outline 0 $govuk-focus-colour !important;
      background: $govuk-focus-light !important;
    }
  }

  .govuk-input:focus,
  .govuk-button:focus:not(:active) {
    outline: $govuk-focus-outline solid $govuk-focus-colour;
    border-color: $govuk-focus-light !important;
  }
}

@media screen and (max-width: 40.0625em) {
  .mobile-break {
    margin-top: 35px;
  }
}

// extra padding to account for -10px margin on header container
.govuk-phase-banner-container {
  padding-top: 10px;
  margin-bottom: -10px;
}

// remove additional space below footer
.govuk-footer {
  padding-bottom: 0 !important;

  aa-copyright {
    margin-top: 5px;
    font-size: 12px;
    a { text-decoration: none;}
  }
}

.govuk-header {
  background: #fff;
}

// child divs inside header container
.govuk-header__container > div {
  align-items: center; /* For vertical align */
  // justify-content: right; /* overridden by server side styles heet */
  display: flex;
  height: $header-height-mobile;
  margin-bottom: 5px;
  padding: 0 0 5px 0;

  @media (min-width: 40.0625em) {
    height: $header-height;
  }
}

.govuk-header__logo {
  float: none;
  width: auto;
  img {
    max-width: 150px;
    max-height: $header-height-mobile;
    //margin-left: auto; //pushes image to the right when using flexbox
  }

  @media (min-width: 40.0625em) {
    img {
      max-width: 200px;
      max-height: $header-height;
    }
  }
}


// Reduce accordion text size
.govuk-accordion__section-button {
  @include govuk-font($size: 19, $weight: normal);
  font-weight: normal;
  color: black !important;
}

/* override text highlighting on active accordion */
.js-enabled {
  .govuk-accordion__section-button:focus {
    .govuk-accordion__section-toggle-focus,
    .govuk-accordion__section-heading-text-focus {
      outline: none;
      background-color: inherit;
      box-shadow: none;
    }
  }
}

.govuk-accordion__section-content {
  //font-size: 1.1875rem;
  @include govuk-font($size: 19, $weight: normal);

  line-height: 1.3157894737;

  margin-bottom: 20px;
  margin-top: 10px;
  padding: 0 0 0 15px !important;
  border-left: solid $govuk-border-colour 4px;

  p { margin-top: 0 }
}

// Remove yellow focus from links and details
div, h1,h2,h3, section {
  &:focus, &.focused {
    outline: $govuk-focus-outline solid $govuk-focus-light;
    background: $govuk-focus-light;
  }
}

/*
.theme-link,
.govuk-back-link,
.govuk-breadcrumbs__link {
  &:focus, &.focused {
    outline: $govuk-focus-outline solid $govuk-focus-colour;
    background: $govuk-focus-light;
  }
}
 */

.govuk-details__text {
  a {
    //@extend %govuk-link;
    word-wrap: break-word;
  }

  a:not([href^='http']):after {
    content:" (" attr(href) ") ";
    font-size: 90%;
    word-wrap: break-word;
  }
}

.grey-box {
  background-color: #eeeeee;
  padding: 0.1em 1em;
  margin-bottom: 1em;
}

.toc {
  margin-bottom: 3em;
}

// https://github.com/UKHomeOffice/DDaT-events/blob/master/docs/assets/sass/docs.scss
.app-prose-scope {

  h1 {
    @extend %govuk-heading-xl;
  }

  h2 {
    @extend %govuk-heading-l;
  }

  h3 {
    @extend %govuk-heading-m;
    margin-bottom: 5px;
  }

  p {
    @extend %govuk-body-m;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  strong,
  b {
    @include govuk-typography-weight-bold;
  }

  ul,
  ol {
    @extend %govuk-list;
  }

  ol {
    @extend %govuk-list--number;
  }

  ul {
    @extend %govuk-list--bullet;
  }

  hr {
    @extend %govuk-section-break;
    @extend %govuk-section-break--visible;
    @extend %govuk-section-break--xl;
  }

  blockquote {
    margin-left: 0;
    @extend .govuk-inset-text;
  }
}
