@media screen {
  .printonly {
    display: none;
  }

  // enable link hints in self serv app only
  // e.g. <span style="display:none" class="link-hint"> (opens new window)</span>
  .link-hint {
    display: inline !important;
  }
}

@media print {
  @page {
    size: auto;   /* auto is the initial value */
    margin: 15mm 20mm;
  }

  .print-footer {
    margin-top: 10px;
    width: 100%;

    p {
      font-size: 10pt;
    }
  }

  .noprint {
    display: none !important;
  }

  .govuk-main-wrapper {
    padding-bottom: 0;
  }

  .govuk-heading-m {
    line-height: 1.25;
  }

  .govuk-caption-m {
    line-height: 1.31578947;
  }

  .govuk-footer {
    border-top: none;
  }

  .govuk-grid-column-one-third,
  .govuk-grid-column-two-thirds {
    width: 75%;
    float: none;
  }

  // ensure left padding
  .govuk-header__content {
    padding-left: 0;
  }

  main {
    a {
      //font-weight: bolder;
      text-decoration: none;
    }

    /* additional classes are appended during the self serv compilation */
    a[href^='http'].print-expand:after {
      content:" (" attr(href) ") ";
      font-size: 90%;
      word-wrap: break-word;
    }

    /* disable gov-uk link text for internal links */
    a[href^="/"]:after {
      content: none !important;
    }

    p, ul, ol, table, .govuk-body-s {
      font-size: 12pt;
      line-height: 1.31578947;
    }

    .govuk-heading-l {
      font-size: 24pt;
      margin-top: 5mm;
      margin-bottom: 5mm;
    }

    .govuk-heading-m {
      line-height: 1.25;
    }

    .govuk-heading-s {
      font-size: 16pt;
    }

    .govuk-details:not(.long-snippet) {
      page-break-inside: avoid;
    }

    .govuk-details > .govuk-details__text {
      display: block;
      padding: 0;
      border: none;
    }

    .govuk-details__summary:before {
      content: none;
    }

    .govuk-details__summary {
      display: block;
      margin-bottom: 15px;
      padding: 0;
      font-weight: 700;
      font-size: 12pt;

      &:before {
        content: none;
      }
    }

    .govuk-details__summary-text {
      text-decoration: none;
    }

    .disclaimer {
      border-top: 1px solid $govuk-border-colour;
      padding-top: 30px;
    }
  }
}
